import React from 'react';
import _ from 'lodash';
import createReactClass from 'create-react-class';
import {bool, object, string} from 'prop-types';
import {mixins, santaTypesDefinitions, utils, constants} from 'santa-components';
import {Video as VideoCore} from 'wix-ui-core/video';
import {PlaybackBIModule} from '@wix/playback-bi-module';

import {biEvents} from './bi/events';
import {videoPlayerBehaviors} from './behaviors/videoPlayerBehaviors';
import style from './VideoPlayer.st.css';
import {playButtonIcon} from './playButtonIcon';
import {intersect} from './intersect';

const {skinBasedComp, runTimeCompData, compStateMixin} = mixins;
const {imageClientApi} = utils;
const {playbackTypes} = constants.MEDIA;
const {ACTION_TYPES} = constants.SITE;

const VIDEO_TYPE_FILE = 'file';
const MAX_LOGO_WIDTH = 126;
const LOGO_TRANSFORM_TYPE = {transformType: 'fit'};
const FITTING_SCALE_TO_FILL = imageClientApi.fittingTypes.SCALE_TO_FILL;

const getComponentSkins = () => ({
  'wixui.skins.VideoPlayer': style.$skin
});

function getPublicState(state) {
  // Handle null state argument (default value):
  const {isPlaying, currentTime, duration, volume, isMuted} = state || {};
  return {
    isPlaying,
    currentTime,
    duration,
    volume,
    isMuted
  };
}

const listenToEventOnce = (eventEmitter, eventName, callback = _.noop) => {
  const handler = () => {
    eventEmitter.off(eventName, handler);
    callback();
  };

  eventEmitter.on(eventName, handler);
};

export class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasBeenInViewport: !intersect.has
    };
    this.placeholderRef = React.createRef();
  }

  componentDidMount() {
    intersect.add(this.placeholderRef.current).then(current => {
      if (current) {
        this.setState({
          hasBeenInViewport: true
        });
      }
    });
  }

  componentWillUnmount() {
    intersect.remove(this.placeholderRef.current);
  }

  render() {
    const {props} = this;

    if (props.lazyLoading && !this.state.hasBeenInViewport && !props.playing) {
      return <div ref={this.placeholderRef} />;
    }

    const styleState = {
      isMobileView: props.isMobileView,
      isDesktopView: !props.isMobileView,
      isTitleExists: Boolean(props.showTitle && props.title)
    };

    const config = _.merge({}, props.config, {
      playable: {
        playButton: <button type="button" className={style.playButton}>{playButtonIcon}</button>,
        preload: 'none',
        modules: {
          [PlaybackBIModule.moduleName]: PlaybackBIModule
        }
      }
    });

    const exProps = _.assign({}, props, {
      config,
      id: `video-player-${props.videoId}`,
      fillAllSpace: true,
      width: props.style.width,
      height: props.style.height
    });

    return (
      <VideoCore
        key={`video${props.controls}${props.showTitle}`}
        {...exProps}
        {...style('root', styleState, props)}
      />
    );
  }
}

VideoPlayer.propTypes = {
  videoId: string,
  controls: bool,
  config: object,
  style: object,
  showTitle: bool,
  title: string,
  isMobileView: bool,
  playing: bool,
  lazyLoading: bool
};

VideoPlayer.displayName = 'VideoPlayer';


export const santaVideoPlayer = createReactClass({
  displayName: 'VideoPlayerSanta',

  mixins: [
    skinBasedComp(getComponentSkins()),
    runTimeCompData,
    compStateMixin(getPublicState)
  ],

  propTypes: {
    styleId: santaTypesDefinitions.Component.styleId,
    style: santaTypesDefinitions.Component.style,
    isMobileView: santaTypesDefinitions.isMobileView,
    id: santaTypesDefinitions.Component.id,
    compProp: santaTypesDefinitions.Component.compProp,
    compData: santaTypesDefinitions.Component.compData,
    reportBI: santaTypesDefinitions.reportBI,
    biVisitorId: santaTypesDefinitions.biVisitorId,
    metaSiteId: santaTypesDefinitions.RendererModel.metaSiteId,
    isPlayingAllowed: santaTypesDefinitions.RenderFlags.isPlayingAllowed,
    staticMediaUrl: santaTypesDefinitions.ServiceTopology.staticMediaUrl,
    staticVideoUrl: santaTypesDefinitions.ServiceTopology.staticVideoUrl,
    registerPlayer: santaTypesDefinitions.Media.registerPlayer,
    unregisterPlayer: santaTypesDefinitions.Media.unregisterPlayer,
    updatePlayerState: santaTypesDefinitions.Media.updatePlayerState,
    lazyLoad: santaTypesDefinitions.Media.lazyLoad,
    isResponsiveMode: santaTypesDefinitions.RendererModel.isResponsive,
  },

  statics: {
    behaviors: videoPlayerBehaviors,
    getComponentSkins
  },

  getInitialState() {
    const {props} = this;
    const duration = _.get(props, 'compData.videoRef.duration');
    const volume = _.get(props, 'compProp.autoplay') ? 0 : 100;
    const isMuted = volume === 0;

    this.delayedActions = new Promise(resolve => {
      this.resolveDelayedActions = resolve;
    });

    return {
      isReady: false,
      isPlaying: false,
      currentTime: 0,
      duration,
      volume,
      isMuted,
      lazyLoading: props.lazyLoad
    };
  },

  componentDidMount() {
    this.props.registerPlayer(this.props.id);
  },

  componentWillUnmount() {
    this.stop();
    this.props.unregisterPlayer(this.props.id);
  },

  delayActionUntilLoaded(action) {
    return this.delayedActions.then(action);
  },

  loadAndDelayAction(action) {
    this.setState({
      lazyLoading: false
    });
    return this.delayActionUntilLoaded(action);
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.isPlayingAllowed && this.props.isPlayingAllowed) {
      this.delayActionUntilLoaded(() => this.player.stop());
    }
  },

  play(callback) {
    this.loadAndDelayAction(
      () => this.player.play()
    ).then(() => callback());
  },

  pause(callback) {
    this.delayActionUntilLoaded(
      () => this.player.pause()
    ).then(() => callback());
  },

  stop(callback) {
    this.delayActionUntilLoaded(
      () => this.player.stop()
    ).then(() => callback());
  },

  togglePlay(callback) {
    this.loadAndDelayAction(
      () => this.player.togglePlay()
    ).then(() => callback());
  },

  mute(callback = _.noop) {
    this.setState({
      isMuted: true
    });

    this.delayActionUntilLoaded(
      () => this.player.mute()
    ).then(() => callback());
  },

  unmute(callback = _.noop) {
    this.setState({
      isMuted: false
    });

    this.delayActionUntilLoaded(
      () => this.player.unMute()
    ).then(() => callback());
  },

  seek(value, callback) {
    const timePoint = Number(value);

    if (_.isFinite(timePoint)) {
      this.delayActionUntilLoaded(
        () => this.player.seekTo(timePoint)
      ).then(() => callback());
    }
  },

  setVolume(value) {
    const volume = Number(value);

    this.setState({
      volume
    });

    this.delayActionUntilLoaded(
      () => this.player.setVolume(volume)
    );
  },

  updatePlaybackState(playbackState) {
    const isPlaying = playbackState === playbackTypes.PLAYING;

    this.setState({isPlaying});
    this.props.updatePlayerState(this.props.id, {playbackState});
  },

  handleFirstPlayRequested() {
    this.props.reportBI(biEvents.PLAY_REQUESTED, {
      videoId: this.getVideoId(),
      videoSource: this.getVideoType(),
      playSource: this.getPlaySource()
    });
  },

  handleFirstPlay() {
    this.props.reportBI(biEvents.PLAY_START, {
      videoId: this.getVideoId(),
      videoSource: this.getVideoType(),
      playSource: this.getPlaySource()
    });
  },

  handleFirstEnded() {
    this.props.reportBI(biEvents.PLAY_DONE, {
      videoId: this.getVideoId(),
      videoSource: this.getVideoType()
    });
  },

  handleInit(playerAPI) {
    this.resolveDelayedActions();

    if (this.getVideoType() === VIDEO_TYPE_FILE) {
      playerAPI.setWixBIVideoID(this.getVideoId());
      playerAPI.setWixVisitorID(this.props.biVisitorId);
      playerAPI.setWixMSID(this.props.metaSiteId);
      playerAPI.setWixBIProduct('editor_player');

      listenToEventOnce(playerAPI, 'engine-state/play-requested', this.handleFirstPlayRequested);
    }
  },

  handleReady() {
    this.setState({
      isReady: true,
      volume: this.player.getVolume(),
      isMuted: this.player.isMuted()
    });

    this.handleAction(ACTION_TYPES.ITEM_READY);
  },

  handleDuration(duration) {
    this.setState({
      duration
    });
  },

  handlePlay() {
    this.updatePlaybackState(playbackTypes.PLAYING);
    this.handleAction(ACTION_TYPES.ON_PLAY);
  },

  handlePause() {
    this.updatePlaybackState(playbackTypes.PAUSED);
    this.handleAction(ACTION_TYPES.ON_PAUSE);
  },

  handleProgress(currentTime) {
    this.setState({
      currentTime,
      volume: this.player.getVolume(),
      isMuted: this.player.isMuted()
    });

    this.handleAction(ACTION_TYPES.ON_PROGRESS, currentTime);
  },

  handleEnded() {
    this.updatePlaybackState(playbackTypes.PLAY_ENDED);
    this.handleAction(ACTION_TYPES.ON_ENDED);
  },

  handleError(err) {
    this.props.reportBI(biEvents.ERROR, {
      errorMessage: err && err.name && err.message ? `${err.name} : ${err.message}` : err,
      videoId: this.getVideoId(),
      videoSource: this.getVideoType(),
      playSource: this.getPlaySource()
    });
  },

  handleMouseIn(e) {
    this.handleAction(ACTION_TYPES.MOUSE_IN, e);
  },

  handleMouseOut(e) {
    this.handleAction(ACTION_TYPES.MOUSE_OUT, e);
  },

  getPlaySource() {
    return this.props.compProp.autoplay ? 'autoplay' : 'click';
  },

  getVideoType() {
    return _.get(this.props, 'compData.videoType', VIDEO_TYPE_FILE);
  },

  getVideoId() {
    return this.props.id;
  },

  getPosterUrl() {
    const {compData, style, staticMediaUrl} = this.props;

    const {posterUrl} = compData;
    if (posterUrl) {
      return posterUrl;
    }

    const posterImageRef = _.get(compData, 'videoRef.posterImageRef');
    if (!posterImageRef) {
      return;
    }

    const {width, height, uri} = posterImageRef;
    const src = {id: uri, width, height};
    const target = {width: style.width, height: style.height};

    if (!target.width || !target.height) {
      target.width = width;
      target.height = height;
    }

    const previewData = imageClientApi.getData(FITTING_SCALE_TO_FILL, src, target);

    return `${staticMediaUrl}/${previewData.uri}`;
  },

  getVideoUrl() {
    const {compData, staticVideoUrl} = this.props;

    const {videoUrl} = compData;
    if (videoUrl) {
      return videoUrl;
    }

    const qualities = _.get(compData, 'videoRef.qualities');
    if (!qualities) {
      return;
    }

    const {url} = _.maxBy(qualities, 'width');

    return `${staticVideoUrl}${url}`;
  },

  getLogoUrl() {
    const {compData, staticMediaUrl} = this.props;
    if (!_.isObject(compData.logoRef)) {
      return;
    }

    const {width, height, uri} = compData.logoRef;
    const src = {
      id: uri,
      width,
      height
    };
    const target = {
      width: MAX_LOGO_WIDTH,
      height: Math.ceil(MAX_LOGO_WIDTH / width * height)
    };
    const previewData = imageClientApi.getData(FITTING_SCALE_TO_FILL, src, target, LOGO_TRANSFORM_TYPE);

    return `${staticMediaUrl}/${previewData.uri}`;
  },

  getSkinProperties() {
    const {props} = this;
    const {compProp, compData, isResponsiveMode} = props;
    const {autoplay} = compProp;
    const controls = compProp.controlsVisibility === 'hover';
    const playing = props.isPlayingAllowed && autoplay;

    const playerProps = {
      className: props.styleId,
      videoId: props.id,
      style: props.style,
      isMobileView: props.isMobileView,
      src: this.getVideoUrl(),
      loop: compProp.loop,
      playing,
      muted: autoplay,
      controls,
      showTitle: compProp.showVideoTitle,
      config: {
        playable: {
          title: compData.videoTitle,
          description: compData.description,
          logoUrl: this.getLogoUrl(),
          poster: this.getPosterUrl(),
        }
      },
      onMouseIn: this.handleMouseIn,
      onMouseOut: this.handleMouseOut,
      onInit: this.handleInit,
      onReady: this.handleReady,
      onDuration: this.handleDuration,
      onProgress: this.handleProgress,
      onPlay: this.handlePlay,
      onPause: this.handlePause,
      onEnded: this.handleEnded,
      onFirstPlay: this.handleFirstPlay,
      onFirstEnded: this.handleFirstEnded,
      onError: this.handleError,
      lazyLoading: this.state.lazyLoading,
      playerRef: player => {
        if (player !== null) {
          this.player = player;
        }
      },
      isResponsiveMode
    };

    return {
      '': {
        children: [
          utils.createReactElement(VideoPlayer, playerProps)
        ]
      }
    };
  }
});
