import _ from 'lodash';

export let intersect;

if (typeof window === 'undefined' || !window.IntersectionObserver) {
  intersect = {
    has: false,
    add: target => Promise.resolve(target),
    remove: _.noop
  };
} else {
  let map;
  let observer;
  intersect = {
    has: true,
    add(target) {
      if (!target) {
        return Promise.resolve(target);
      }

      if (!observer) {
        map = new Map();
        observer = new window.IntersectionObserver(entries => {
          _(entries)
            .filter(({isIntersecting}) => isIntersecting)
            .forEach(({target}) => {
              const resolve = map.get(target);
              if (resolve) {
                intersect.remove(target);
                resolve(target);
              }
            });
        }, {
          rootMargin: '50% 0px'
        });
      }

      return new Promise(resolve => {
        map.set(target, resolve);
        observer.observe(target);
      });
    },
    remove(target) {
      if (target && map) {
        map.delete(target);
        if (map.size === 0) {
          observer.disconnect();
          observer = null;
          map = null;
        }
      }
    }
  };
}
