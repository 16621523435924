import React from 'react';

export const playButtonIcon = (
  <svg role="presentation" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" preserveAspectRatio="xMidYMin slice" width="100%">
    <g fill="none" fillRule="evenodd">
      <circle cx="18" cy="18" r="17" stroke="#fff" strokeWidth="2"/>
      <path fill="#fff" d="M23.935 17.708l-10.313 6.033V11.676z"/>
    </g>
  </svg>
);
